<template>
  <div id="wrapper" class="admin-panel">
    <t-side-nav></t-side-nav>
    <t-header></t-header>
    <div class="page-content">
      <div class="page-content-inner">
        <router-view />
        <t-footer></t-footer>
      </div>
    </div>
  </div>
</template>

<script>

import Header from "@/view/company/layouts/Header";
import Footer from "@/view/company/layouts/Footer";
import SideNav from "@/view/company/layouts/SideNav";
import { LOGOUT } from "@/core/services/store/auth.module";
//import "@/core/config/echo"

export default {
  name: "DashboardLayout",
  components: {
    't-header': Header,
    't-footer': Footer,
    't-side-nav': SideNav,
  },
  mounted() {
    // Todo check user has company admin role for access here
    let token = this.secureLocalStorage('token');

    let user = JSON.parse(this.secureLocalStorage('user'));
    let isAdmin = false;
    if (user && user.roles && user.roles.length) {
      isAdmin = !!user.roles.find(item => item.id == 1 || item.id == 2)
    }

    if (!token || !isAdmin) {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  }
}
</script>


<style scoped></style>
