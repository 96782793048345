<template>
  <div class="page-menu">
    <div class="responsive"></div>

    <!-- btn close on small devices -->
    <span class="btn-menu-close" uk-toggle="target: #wrapper ; cls: mobile-active"></span>
    <!-- traiger btn -->
    <span class="btn-menu-trigger" uk-toggle="target: .page-menu ; cls: menu-large"></span>
    <!-- logo -->
    <div class="logo uk-visible@s" style="justify-content: center;">
      <router-link to="/admin/dashboard">
        <img style="width:100%" alt="" :src="logo" />
      </router-link>
    </div>
    <div class="page-menu-inner" data-simplebar>
      <form class="menu-search uk-search">
        <label for="menusearch" style="position: absolute; top: 17px; left: 45px"><i class="uil-search-alt"></i></label>
        <input id="menusearch" class="uk-search-input" v-model="searchForMenu" autocomplete="off"
          :placeholder="$t('general.search_in_menu')" style="height: 38px; border-radius: 5px; padding-left: 40px"
          type="search" />
      </form>
      <ul class="mt-0 pb-5 sideNavMenu" id="admin_sideNavMenu">
        <li>
          <router-link :to="{ name: 'company.dashboard' }">
            <i class="uil-home-alt"></i>
            <span class="sideNavMenu_item_title">
              {{ $t("sideNav.Login") }}</span>
          </router-link>
        </li>
        <li class="sideNavMenu_item_parent_title" v-if="this.CatalogTrainingVisible">
          <a href="javascript:voi(0)"><i class="uil-bell"></i><span class="sideNavMenu_item_title">{{
            this.$t('general.requests_my_demands') }}
            </span>
            <span class="nav-tag" v-if="superAdmin()">{{
              userPurchaseCount + userRequestCount
            }}</span>
            <span class="nav-tag" v-else>{{ userRequestCount }}</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.requests.user' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.user_requests') }}</span>
                <span class="nav-tag white">{{ userRequestCount }}</span>
              </router-link>
            </li>
            <li v-if="superAdmin()">
              <router-link :to="{ name: 'company.requests.purchase' }">
                <span class="sideNavMenu_item_title">Satınalma Taleplerim
                </span>
                <span class="nav-tag white">{{ userPurchaseCount }}</span>
                <!-- <span class="nav-tag white">3</span> -->
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title" v-if="superAdmin()">
          <a href="javascript:void(0)">
            <i class="uil-building"></i><span class="sideNavMenu_item_title">Kurumlar</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.institutions.add' }">
                <span class="sideNavMenu_item_title">Kurum Ekle</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.institutions.list' }">
                <span class="sideNavMenu_item_title">Kurum Listesi</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title" v-if="superAdmin()">
          <a href="javascript:void(0)">
            <i class=" uil-store-alt"></i><span class="sideNavMenu_item_title">Firmalar</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.companies.add' }">
                <span class="sideNavMenu_item_title">Firma Ekle</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.companies.list' }">
                <span class="sideNavMenu_item_title">Firma Listesi</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="javascript:void(0)">
            <i class="uil-users-alt"></i><span class="sideNavMenu_item_title">
              {{ $t("users.users") }}</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.user.add' }">
                <span class="sideNavMenu_item_title">{{
                  $t("menu.company_dashboard.add_users")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.user.list' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.user_list') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.user.group' }">
                <span class="sideNavMenu_item_title">{{
                  $t("user_group.title")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.user.passive.list' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.inactive_users_groups') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="#">
            <i class="uil-book-open"></i>
            <span class="sideNavMenu_item_title">{{ this.$t('general.contents') }}</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.content.add' }">
                <span class="sideNavMenu_item_title">
                  {{
                    $t("menu.company_dashboard.create_contact_material")
                  }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.contents.all' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.all_contents') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="#"><i class="uil-tag-alt"></i>
            <span class="sideNavMenu_item_title"> {{ this.$t('general.tags') }}</span></a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.hashtags.add' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.add_tag') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.hashtags.list' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.tag_list') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="#">
            <i class="uil-file-plus-alt"></i>
            <span class="sideNavMenu_item_title">{{ this.$t('general.programs') }}</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.program.add' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.add_program') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.programs.all' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.all_programs') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="#"><i class="uil-location-arrow"></i>
            <span class="sideNavMenu_item_title">
              {{ $t("menu.company_dashboard.assignments") }}</span></a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.assignments.add' }">
                <span class="sideNavMenu_item_title">
                  {{ $t("menu.company_dashboard.create_assignment") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.list' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.all_assignments') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.auto' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.auto_assignments') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title" v-if="CatalogTrainingVisible">
          <a href="javascript:void(0)"><i class="uil-books"></i>
            <span class="sideNavMenu_item_title">{{ this.$t('general.catalog_trainings') }}</span></a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.content.catalog.add' }"><span class="sideNavMenu_item_title">
                  Katalog Eğitimi Ekle</span></router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.education.catalog.list' }"><span class="sideNavMenu_item_title">
                  {{ this.$t('general.all_catalog_trainings') }}</span></router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.education.catalog.purchased' }"><span class="sideNavMenu_item_title">
                  {{ this.$t('general.purchased_catalog_trainings') }}</span></router-link>
            </li>
          </ul>
        </li>
        <!-- <li><a href="dashboard.html"><i class="uil-bolt-alt"></i> <span class="sideNavMenu_item_title"> Düellolar</span></a> </li>
        </li> -->
        <li class="sideNavMenu_item_parent_title">
          <a href="#"><i class="uil-notes"></i>
            <span class="sideNavMenu_item_title">{{ this.$t('general.all_surveys') }}</span></a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.survey.add' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.add_survey') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.surveys.list' }">
                <span class="sideNavMenu_item_title"> {{ this.$t('survey.surveys') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.evaluation-survey.add' }">
                <span class="sideNavMenu_item_title">
                  {{ $t("evaluation_survey.add") }}
                </span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.evaluation-surveys.list' }">
                <span class="sideNavMenu_item_title">
                  {{ $t("menu.company_dashboard.evaluation_surveys") }}
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="#">
            <i class="uil-pen"></i>
            <span class="sideNavMenu_item_title"> {{ $t("exam.exams") }}</span>
          </a>
          <ul>
            <li>
              <router-link :to="{ name: 'company.exam.add' }">
                <span class="sideNavMenu_item_title">{{ $t("exam.add") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.exams.list' }">
                <span class="sideNavMenu_item_title">{{
                  $t("exam.exams")
                }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sideNavMenu_item_parent_title">
          <a href="#"><i class="uil-chart-pie"></i>
            <span class="sideNavMenu_item_title">{{ this.$t('general.reports') }}</span></a>
          <ul>
            <li v-if="superAdmin()">
              <router-link :to="{ name: 'company.reports.statistics' }">
                <span class="sideNavMenu_item_title">Rapor istatistikleri</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.institutions' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.organization_reports') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.licences' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.business_partner_license_reports') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.users' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.user_reports') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.certificates' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.certification_reports') }}</span>
              </router-link>
            </li>
            <li v-if="this.$isGiftVisible">
              <router-link :to="{ name: 'company.reports.gifts' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.gift_reports') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.surveyReports' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.survey_reports') }}</span>
              </router-link>
            </li>
            <!--            <li>-->
            <!--              <router-link-->
            <!--                :to="{ name: 'company.reports.evaluationSurveyReports' }"-->
            <!--              >-->
            <!--                <span class="sideNavMenu_item_title"-->
            <!--                  >Değerlendirme Anketi Raporları</span-->
            <!--                >-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li>
              <router-link :to="{ name: 'company.reports.educationEndGeneralEvaluationReport' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.end_of_training_general_evaluation_report')
                  }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.educationEndDetailEvaluationReport' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.end_of_training_detailed_evaluation_report')
                  }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.userAssignments' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.user_assignment _reports') }}</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'company.reports.contents' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.content_reports') }}</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'company.reports.userContents' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.user_content_report') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.exams' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.exam_general_report') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.examResults' }">
                <span class="sideNavMenu_item_title">{{ this.$t('general.exam_detailed_report') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.selfRegistiration' }">
                <span class="sideNavMenu_item_title">Katalog Eğitimleri Raporları</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.reports.linkedin-learning' }">
                <span class="sideNavMenu_item_title">Linkedin Learning Raporu</span>
              </router-link>
            </li>
          </ul>
        </li>
        <ul :data-submenu-title="this.$t('general.management_tools')" id="general_sub_menu_list">
          <li>
            <router-link :to="{ name: 'company.setting' }">
              <i class="uil-cog"></i>

              <span class="sideNavMenu_item_title">
                {{ $t("menu.company_dashboard.setting") }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'company.data' }">
              <i class="uil-database-alt"></i>
              <span class="sideNavMenu_item_title">
                {{ $t("menu.company_dashboard.data") }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'company.point.list' }">
              <i class="uil-game-structure"></i>
              <span class="sideNavMenu_item_title">
                {{ $t("point.gamification") }}
              </span>
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'company.management.share' }">
              <i class="uil-comment-check"></i>
              <span class="sideNavMenu_item_title">
                {{ $t("menu.company_dashboard.manage_posts") }}</span>
              <span class="nav-tag">{{ userShareCount }}</span>
            </router-link>
          </li> -->

          <li class="sideNavMenu_item_parent_title">
            <a href="#"><i class="uil-check-square"></i>
              <span class="sideNavMenu_item_title">Onaylarım</span></a>
            <ul>
              <li>
                <router-link :to="{ name: 'company.management.share' }">
                  <span class="sideNavMenu_item_title"> {{ $t("menu.company_dashboard.manage_posts") }}</span>
                  <span class="nav-tag">{{ userShareCount }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ee' }">
                  <span class="sideNavMenu_item_title">İçerik Onaylarım</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <router-link :to="{ name: 'company.certificate.list' }">
              <i class="uil-medal"></i>
              <span class="sideNavMenu_item_title">{{
                $t("certificate.management")
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'company.certificate_template.list' }">
              <i class="uil-object-group"></i>
              <span class="sideNavMenu_item_title">{{
                $t("certificate_template.management")
              }}</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'company.mail.templates' }">
              <i class="uil-mailbox"></i>
              <span class="sideNavMenu_item_title"> {{ this.$t('general.manage_email_templates') }}</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'company.integrations.list' }"><i class="uil-puzzle-piece"></i>
              <span class="sideNavMenu_item_title">
                <!-- {{ $t("menu.company_dashboard.announcement") }} -->
                {{ this.$t('api_integration.api_integration') }}
              </span>
            </router-link>
          </li>

          <li class="sideNavMenu_item_parent_title" v-if="this.$isGiftVisible">
            <a href="#"><i class="uil-gift"></i>
              <span class="sideNavMenu_item_title">{{ this.$t('general.manage_gifts') }}</span></a>
            <ul>
              <li>
                <router-link :to="{ name: 'company.gift.requests' }">
                  <span class="sideNavMenu_item_title">{{ this.$t('general.gift_requests') }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'company.gift.add' }">
                  <span class="sideNavMenu_item_title">{{ this.$t('general.add_gift') }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'company.gift.list' }">
                  <span class="sideNavMenu_item_title">{{ this.$t('general.gift_list') }}</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="sideNavMenu_item_parent_title">
            <a href="#"><i class="uil-question-circle"></i>
              <span class="sideNavMenu_item_title">
                {{ this.$t('general.faq') }}</span></a>
            <ul>
              <li>
                <router-link :to="{ name: 'company.faq.list' }">
                  <span class="sideNavMenu_item_title">S.S.S. Listesi</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'company.faq.add' }">
                  <span class="sideNavMenu_item_title">{{ this.$t('faq.add') }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'company.faq_categories.list' }">
                  <span class="sideNavMenu_item_title">{{ $t('general.category_list') }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'company.faq_categories.add' }">
                  <span class="sideNavMenu_item_title">{{ $t('general.add_category') }}</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <router-link :to="{ name: 'company.advertisement.list' }"><i class="icon-line-awesome-buysellads"></i>
              <span class="sideNavMenu_item_title">
                {{ $t("menu.company_dashboard.advertisement") }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'company.announcement.list' }"><i class="uil-megaphone"></i>
              <span class="sideNavMenu_item_title">
                {{ $t("menu.company_dashboard.announcement") }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'company.menu_modules.list' }"><i class="fab fa-mendeley"></i>
              <span class="sideNavMenu_item_title">{{ this.$t('general.manage_menu_modules') }}</span>
            </router-link>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import store from "@/core/services/index";
import axios from "axios";

import company_module, {
  SELF_URL as COMPANY_SELF_URL,
  GET_SELF as COMPANY_GET_SELF,
  SELF as COMPANY_SELF,
  MODULE_NAME as COMPANY_MODULE_NAME,
} from "@/core/services/store/company.module";

import trainingModule, {
  MODULE_NAME as TRAINING_MODULE_NAME,
  GET_REQUEST_COUNT as GET_TRAINING_USER_REQUEST_COUNT,
  REQUEST_COUNT as TRAINING_USER_REQUEST_COUNT,
} from "@/core/services/store/training.module";

import purchaseModule, {
  MODULE_NAME as PURCHASE_MODULE_NAME,
  GET_REQUEST_COUNT as GET_PURCHASE_USER_REQUEST_COUNT,
  REQUEST_COUNT as PURCHASE_USER_REQUEST_COUNT,
} from "@/core/services/store/training_purchase.module";

const _COMPANY_MODULE_NAME = COMPANY_MODULE_NAME;
const _TRAINING_MODULE_NAME = TRAINING_MODULE_NAME;
const _PURCHASE_MODULE_NAME = PURCHASE_MODULE_NAME;

export default {
  name: "SideNav",
  data() {
    return {
      userShareCount: 0,
      searchForMenu: "",
      trainingUserRequestUrl: "api/training-requests?count=true",
      purchaseUserRequestUrl: "api/purchase-request-count",
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_COMPANY_MODULE_NAME, company_module);
    registerStoreModule(_TRAINING_MODULE_NAME, trainingModule);
    registerStoreModule(_PURCHASE_MODULE_NAME, purchaseModule);
  },
  computed: {
    company: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + COMPANY_SELF];
      },
      set(value) { },
    },
    userRequestCount() {
      return store.getters[
        _TRAINING_MODULE_NAME + "/" + TRAINING_USER_REQUEST_COUNT
      ];
    },
    userPurchaseCount() {
      return store.getters[
        _PURCHASE_MODULE_NAME + "/" + PURCHASE_USER_REQUEST_COUNT
      ];
    },
    logo() {
      if (this.company == null) return "";
      return this.company.logo_link;
    },
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() { },
    },
  },
  methods: {
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    getCompanyItem() {
      if (this.company == null || this.company.logo_link == undefined)
        this.$store.dispatch(COMPANY_MODULE_NAME + "/" + COMPANY_GET_SELF, {
          url: COMPANY_SELF_URL,
        }).then((response) => {
          const root = document.querySelector(':root');
          let settings = response?.settings.find(x => x.key === 'theme_settings');
          settings ? root.style.setProperty('--iconColor', settings?.value?.iconColor) : root.style.setProperty('--iconColor', '#93949c');
          settings ? root.style.setProperty('--backgroundColor', settings?.value?.backgroundColor) : root.style.setProperty('--backgroundColor', '#666CFF');
        });
    },
    getUserTrainingRequestCount() {
      if (!this.CatalogTrainingVisible) {
        return false;
      }
      this.$store.dispatch(
        _TRAINING_MODULE_NAME + "/" + GET_TRAINING_USER_REQUEST_COUNT,
        {
          url: this.trainingUserRequestUrl,
        }
      );
    },
    getUserPurchaseRequestCount() {
      this.$store.dispatch(
        _PURCHASE_MODULE_NAME + "/" + GET_PURCHASE_USER_REQUEST_COUNT,
        {
          url: this.purchaseUserRequestUrl,
        }
      );
    },
    getUserShareCount() {
      axios
        .get(`api/shares-management`, {
          params: {
            count: true,
          },
          headers: this.apiHeaders,
        })
        .then((result) => {
          this.userShareCount = result.data;
        });
    },
  },
  mounted() {
    this.getCompanyItem();
    this.getUserTrainingRequestCount();
    this.getUserPurchaseRequestCount();
    this.getUserShareCount();
    $(".sideNavMenu li a").on("click", function (e) {
      if (
        $(this)
          .closest("li")
          .children("ul").length
      ) {
        if (
          $(this)
            .closest("li")
            .is(".active-submenu")
        ) {
          $(".page-menu ul li").removeClass("active-submenu");
        } else {
          $(".page-menu ul li").removeClass("active-submenu");
          $(this)
            .parent("li")
            .addClass("active-submenu");
        }
        e.preventDefault();
      } else {
        if (
          $(this)
            .parent("li")
            .parent("ul")
            .parent("li")
            .parent("ul").length == 0
        )
          $(".page-menu ul li").removeClass("active-submenu");
        $(this)
          .parent("li")
          .addClass("active-submenu");
      }
    });
  },
  watch: {
    searchForMenu(val) {
      val = this.turkishToLower(val.trim().toString());
      let general_sub_menu = document.getElementById("general_sub_menu_list");
      var nav_spans = document
        .getElementById("admin_sideNavMenu")
        .querySelectorAll(".sideNavMenu_item_title");
      nav_spans.forEach((span) => {
        let closest_li = span.closest("li");
        let closest_li_classlist = closest_li.classList;
        if (
          this.turkishToLower(span.innerText.trim().toString()).includes(val) ||
          val == ""
        ) {
          closest_li_classlist.remove("hidden");
        } else {
          if (closest_li_classlist.contains("sideNavMenu_item_parent_title")) {
            if (
              closest_li.querySelectorAll("li.hidden").length ==
              closest_li.querySelectorAll("li").length
            ) {
              closest_li_classlist.add("hidden");
            }
          } else {
            closest_li_classlist.add("hidden");
          }
        }
      });
      if (
        general_sub_menu != null &&
        general_sub_menu.querySelectorAll("li").length ==
        general_sub_menu.querySelectorAll("li.hidden").length
      ) {
        general_sub_menu.classList.add("hidden");
      } else {
        general_sub_menu.classList.remove("hidden");
      }
    },
    $route(to, from) {
      $("#admin_sideNavMenu ul li a").each(function () {
        let url = $(this)
          .attr("href")
          .substring(1);
        if (url !== to.path) {
          $(this)
            .parent()
            .removeClass("active-submenu");
        }
      });
    },
  },
};
</script>
<style scoped>
.hidden {
  display: none !important;
}
</style>
<style>
.admin-panel>.page-menu>.page-menu-inner>.simplebar-wrapper>.simplebar-mask>.simplebar-offset>.simplebar-content {
  overflow-y: auto !important;
}

.menu-large ul li {
  white-space: nowrap !important;
}

.menu-large ul li ul li {
  padding-left: 25px !important;
}

.menu-large ul li ul li span {
  padding-left: 25px !important;
  white-space: nowrap !important;
  max-width: 50px !important;
  text-overflow: ellipsis !important;
}
</style>
